let _audioContext = new AudioContext();
let _running = false;
resetAudio();

export function isRunning() {
  return _running;
}

export function getContext() {
  return _audioContext;
}

export function resetAudio() {
  _audioContext.close();
  _audioContext = new AudioContext();
  _audioContext.addEventListener('statechange', ev => {
    if(_audioContext.state === 'running') {
      _running = true;
    }
  });
}


export function init_audio() {
  if (_audioContext.state === 'suspended') {
    _audioContext.resume();
  }
  if (_audioContext.state !== 'suspended') {
    document.removeEventListener('click', init_audio);
    document.removeEventListener('touchend', init_audio);
    document.removeEventListener('mousedown', init_audio);
  }
}
document.addEventListener('click', init_audio);
document.addEventListener('touchend', init_audio);
document.addEventListener('mousedown', init_audio);

export function connect(audioSource) {
  audioSource.connect(_audioContext.destination);
}
