import * as api from './api.js';
import * as api_fragments from './fragments.js';

let uploadElement = document.getElementById('file-upload'); // TODO: can this line be deleted?

export async function upload(filename, file) {
  api_fragments.addProgress();
  let url = 'uploads/upload/';
  url += filename;;
  let res = await api.auth_put_file(url, file);
  api_fragments.removeProgress();
  return res;
}

export async function anonUpload(filename, file) {
  api_fragments.addProgress();
  let url = 'uploads/anon_upload/';
  url += filename;;
  let res = await api.auth_put_file(url, file);
  api_fragments.removeProgress();
  return res;
}
