import * as settings from './settings.js';

var textFile = null, makeTextFile = function(text) {
    var data = new Blob([text], {type: 'text/plain'});

    // If we are replacing a previously generated file we need to
    // manually revoke the object URL to avoid memory leaks.
    if (textFile !== null) {
        window.URL.revokeObjectURL(textFile);
    }

    textFile = window.URL.createObjectURL(data);

    // returns a URL you can use as a href
    return textFile;
};

export function downloadURL(url) {
    var link = document.createElement('a');
    let filename = url.split('/').pop();
    link.setAttribute('download', filename);
    link.href = settings.get_media_url() + url;
    document.body.appendChild(link);

    // wait for the link to be added to the document
    window.requestAnimationFrame(function () {
        var event = new MouseEvent('click');
        link.dispatchEvent(event);
        document.body.removeChild(link);
    });
}

export function downloadText(text) {
    var link = document.createElement('a');
    link.setAttribute('download', 'text.txt');
    link.href = makeTextFile(text);
    document.body.appendChild(link);

    // wait for the link to be added to the document
    window.requestAnimationFrame(function () {
        var event = new MouseEvent('click');
        link.dispatchEvent(event);
        document.body.removeChild(link);
    });
}
