import {TextFragment} from './text_fragment.js';

// everything but the type is inherited from text
// fragment for now.
// the type changes the behaviour in manipulations such
// that a file is uploaded
class FileFragment extends TextFragment {
}

export default FileFragment;
