import * as login from './login.js';
import * as settings from '../settings.js';

//export let base_url = settings.API_BASE_URL;
export let base_url = settings.get_api_url();

async function handleResponse(res) {
  let text = null;
  let json = null;
  let contentType = res.headers.get('content-type');
  if(contentType !== null) {
    if(contentType.indexOf('text') !== -1) {
      text = await res.text();
    }
    if(contentType.indexOf('json') !== -1) {
      json = await res.json();
    }
  }
  if(!res.ok) {
    console.error(res);
  }
  return {res: res, json:json, text:text};
}

export async function put_file(path, file) {
  let req = build_put_file(path, file);
  const res = await fetch(req);
  return await handleResponse(res);
}
export async function auth_put_file(path, file) {
  let req = build_put_file(path, file, true);
  const res = await fetch(req);
  return await handleResponse(res);
}
export async function auth_put(path, data) {
  let req = build_put(path, data, true);
  const res = await fetch(req);
  return await handleResponse(res);
}
export async function get(path, parameters=null) {
  let req = build_get(path, parameters);
  const res = await fetch(req.myRequest, req.myInit);
  return await handleResponse(res);
}
export async function auth_get(path, parameters=null) {
  let req = build_get(path, parameters, true);
  const res = await fetch(req.myRequest, req.myInit);
  return await handleResponse(res);
}
export async function post(path, body) {
  let req = build_post(path, body);
  const res = await fetch(req);
  return await handleResponse(res);
}
export async function auth_post(path, body) {
  let req = build_post(path, body, true);
  const res = await fetch(req);
  return await handleResponse(res);
}
export async function delete_req(path, body) {
  let req = build_delete(path, body);
  const res = await fetch(req);
  return await handleResponse(res);
}
export async function auth_delete_req(path, body) {
  let req = build_delete(path, body, true);
  const res = await fetch(req);
  return await handleResponse(res);
}

function build_get(path, parameters, authorized=false) {
  path = base_url + path;
  let url = new URL(path, document.URL);
  if(parameters !== null) {
    url.search = new URLSearchParams(parameters);
  }
  let myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  let token = login.getCreds().token;
  if(authorized && token !== "") {
    myHeaders.append('Authorization', 'Token ' + token);
  }
  const myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
  };
  let myRequest = new Request(url, myInit);
  return {myRequest: myRequest, myInit: myInit};
}

function build_request(
  method,
  path,
  body,
  authorized,
  contentType='application/json')
{
  path = base_url + path;
  let myHeaders = new Headers();
  myHeaders.append('Content-Type', contentType);
  let token = login.getCreds().token;
  if(authorized && token !== "") {
    myHeaders.append('Authorization', 'Token ' + token);
  }
  const myInit = {
    method: method,
    headers: myHeaders,
    mode: 'cors',
    body: JSON.stringify(body)
  };
  let myRequest = new Request(path, myInit);
  return myRequest;
}

function build_delete(path, body, authorized=false) {
  return build_request('DELETE', path, body, authorized);
}

function build_post(path, body, authorized=false)  {
  path = base_url + path;
  let myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  let token = login.getCreds().token;
  if(authorized && token !== "") {
    myHeaders.append('Authorization', 'Token ' + token);
  }
  const myInit = {
    method: 'POST',
    headers: myHeaders,
    mode: 'cors',
    body: JSON.stringify(body)
  };
  let myRequest = new Request(path, myInit);
  return myRequest;
}

function build_put(path, data, authorized=false, contentType='application/json')  {
  path = base_url + path;
  let myHeaders = new Headers();
  myHeaders.append('Content-Type', contentType);
  let token = login.getCreds().token;
  if(authorized && token !== "") {
    myHeaders.append('Authorization', 'Token ' + token);
  }
  const myInit = {
    method: 'PUT',
    headers: myHeaders,
    mode: 'cors',
    body: JSON.stringify(data)
  };
  let myRequest = new Request(path, myInit);
  return myRequest;
}

function build_put_file(path, file, authorized=false)  {
  if(authorized && !login.loggedIn()) {
    //return;
  }
  path = base_url + path;
  let myHeaders = new Headers();
  myHeaders.append('Content-Type', file.type);
  let token = login.getCreds().token;
  if(authorized && token !== "") {
    myHeaders.append('Authorization', 'Token ' + token);
  }
  const myInit = {
    method: 'PUT',
    headers: myHeaders,
    mode: 'cors',
    body: file
  };
  let myRequest = new Request(path, myInit);
  return myRequest;
}
