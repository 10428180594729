import * as api from './api.js';

export async function search_and_replace_scripts(search, replace) {
  let res = await api.auth_post('room/search_and_replace_scripts/', {
    search: search,
    replace: replace
  });
  if(!res.res || !res.res.ok) {
    console.error("Problem search/replace. Response object:");
    console.error(res);
  }
  else {
    console.log("Success for /room/search_and_replace_scripts/");
    console.log(JSON.parse(res.json));
  }
  return res;
}

export async function search_scripts(search) {
  let res = await api.auth_get('room/search_scripts/', {
    search: search
  });
  if(!res.res || !res.res.ok) {
    console.error("Problem search. Response object:");
    console.error(res);
  }
  else {
    console.log("Success for /room/search_scripts/");
    console.log(JSON.parse(res.json));
  }
  return res;
}

export async function list_script_lines() {
  let res = await api.auth_get('room/list_script_lines/', {});
  if(!res.res || !res.res.ok) {
    console.error("Problem search. Response object:");
    console.error(res);
  }
  else {
    console.log("Success for /room/list_script_lines/");
    console.log(JSON.parse(res.json));
  }
  return res;
}
