import {Change} from './Change.js';
import {_updateFragments} from './utilities.js';
import * as positions from '../positions.js';

/**
 * a PositionChange object applies translations to fragments every frame
 * and accumulates them ({@link PositionChange#progress}) until
 * {@link PositionChange#finalize} is called.
 * the accumulated position changes are submitted to the database when
 * {@link PositionChange#finalize} is called.
 * for database synchronization the {@link PositionChange#s_finalize} calls
 * {@link src/changes/util.js~_updateFragments}.
 */
export class PositionChange extends Change {
  /**
   * @param {float} dx - Translation in x
   * @param {float} dy - Translation in y
   */
  constructor(fragments, dx, dy) {
    super(fragments);
    let globalDiff = positions.screenToGlobalDiff({dx: dx, dy: dy})
    dx = globalDiff.dx;
    dy = globalDiff.dy;
    this.dx = dx;
    this.dy = dy;
    this._fragments.forEach(function(fragment) {
      positions.moveFragment(fragment, dx, dy);
    });
  }
  /**
   * the progress function accumulates translation over multiple
   * calls and directly applies translation to fragments for every
   * call.
   * the progress function might be called with zero movement.
  */
  progress(dx, dy) {
    let globalDiff = positions.screenToGlobalDiff({dx: dx, dy: dy})
    let gdx = globalDiff.dx;
    let gdy = globalDiff.dy;
    this.dx += gdx;
    this.dy += gdy;
    this._fragments.forEach(function(fragment) {
      positions.moveFragment(fragment, gdx, gdy);
    });
  }
  s_finalize(dx, dy) {
    let self = this;
    let globalDiff = positions.screenToGlobalDiff({dx: dx, dy: dy})
    dx = globalDiff.dx;
    dy = globalDiff.dy;
    this.progress(dx, dy);
    _updateFragments(this._fragments);
  }
  undo() {
    let self = this;
    this._fragments.forEach(function(fragment) {
      positions.moveFragment(fragment, -self.dx, -self.dy);
    });
    _updateFragments(this._fragments);
  }
  redo() {
    let self = this;
    this._fragments.forEach(function(fragment) {
      positions.moveFragment(fragment, self.dx, self.dy);
    });
    _updateFragments(this._fragments);
  }
}
