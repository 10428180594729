import * as main from '../main.js';

let subtypes = {}
let typenames = {}

export function registerSubtype(typeID, type) {
  if(typeof typeID !== 'string' || typeof type !== 'function') {
    throw Error(`id must be string and type must be constructor function`);
  }
  else if(subtypes[typeID] !== undefined) {
    throw Error(`Subtype by id ${typeID} already registered!`);
  }
  else {
    subtypes[typeID] = type;
    typenames[type] = typeID;
  }
}

// TODO: rename to produceFragment
export function getFragment(typeID, x, y, data, persistent=false) {
  let p = main.getSketch();
  if(subtypes[typeID] === undefined) {
    throw Error(`Subtype by id ${typeID} is not registered!`);
  }
  else {
    // TODO dont require type in fragments
    let frag = new subtypes[typeID](p, x, y, typeID, data, persistent);
    return frag;
  }
}

// TODO: mabe move tp base fragment
export function getTypename(type) {
  if(typenames[type] === undefined) {
    throw Error(`Subtype ${type} is not registered!`);
  }
  else {
    return typenames[type];
  }
}
