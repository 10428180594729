import * as api_space from './api/space.js';
import * as api_login from './api/login.js';

function getStorePrefix(global=false)  {
  if(global) {
    return 'global/';
  }
  else {
    let user = api_login.getUsername();
    let space = api_space.getSpace();
    let prefix = ""
    if(api_login.loggedIn()) {
      prefix += api_login.getUsername() + "/";
    }
    if(space !== null) {
      prefix += space.username + '/' + space.name + '/';
    }
    return prefix;
  }
}

export function getItem(key, global=false) {
  let prefix = getStorePrefix(global);
  return window.localStorage.getItem(prefix + key) || 1;
}

export function setItem(key, val, global=false) {
  let prefix = getStorePrefix(global);
  window.localStorage.setItem(prefix + key, val);
}
