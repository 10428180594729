import {Change} from './Change.js';
import {_deleteFragments} from './utilities.js';
import {_updateFragments} from './utilities.js';
import * as positions from '../positions.js';

export class DeleteFragmentsChange extends Change {
  constructor(fragments) {
    super(fragments);
    positions.removeFragments(this._fragments);
  }
  progress() {
  }
  s_finalize() {
    _deleteFragments(this._fragments);
  }
  undo() {
    const sync = false;
    positions.insertFragments(this._fragments, sync);
    _updateFragments(this._fragments, true);
  }
  redo() {
    positions.removeFragments(this._fragments);
    _deleteFragments(this._fragments);
  }
}
