import {Change} from './Change.js';
import {_updateFragments} from './utilities.js';
import {_deleteFragments} from './utilities.js';
import {_createFragments} from './utilities.js';
import * as positions from '../positions.js';

export class AddFragmentsChange extends Change {
  constructor(fragments) {
    super(fragments);
    const sync = false;
    positions.addFragmentsOnTop(this._fragments, sync);
  }
  progress(fragments) {
    const sync = false;
    positions.addFragmentsOnTop(fragments, sync);
    this.addFragments(fragments);
  }
  s_finalize() {
    _createFragments(this._fragments);
  }
  undo() {
    positions.removeFragments(this._fragments);
    _deleteFragments(this._fragments);
  }
  redo() {
    const sync = false;
    positions.addFragmentsOnTop(this._fragments, sync);
    _updateFragments(this._fragments);
  }
}
