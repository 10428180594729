import {Change} from './Change.js';

export class OngoingChange extends Change {
  constructor(fragments, timeout=1000) {
    super(fragments);
    this.timeout = timeout;
    this.startTimer();
  }
  s_finalize() {
    this.stopTimer();
  }
  resetTimer() {
    this.stopTimer();
    this.startTimer();
  }
  startTimer() {
    let self = this;
    if(this.timeout >= 0) {
      this.timer = setTimeout(function() {
        self.finalize();
      }, this.timeout);
    }
  }
  stopTimer() {
    if(this.timer) {
      clearTimeout(this.timer);
    }
  }
}
