import * as positions from './positions.js';
import * as main from './main.js';
import {reverse} from './utilities/utilities.js';
import * as settings from './settings.js';

let selectionState = null;
initSelectionStates();

function initSelectionStates() {
  selectionState = {
    moving: false,
    startX: null,
    startY: null,
    curX: null,
    curY: null,
    previouslySelectedFragments: [], // Internal state: fragments that have already been selected before
    fragments: [] // Currently selected fragments
  };
}

export function selectionContains(fragment) {
  return selectionState.fragments.indexOf(fragment) !== -1;
}
export function resetSelection() {
  removeFromSelection(selectionState.fragments);
  initSelectionStates();
}
export function getSelectedFragments() {
  return selectionState.fragments;
}
export function isSelectionActive() {
  return selectionState.fragments.length !== 0
}
export function isPerformingSelection() {
  return selectionState.moving;
}
export function toggleFragmentSelectionAt(position) {
  reverse(positions.onScreenFragments(), function(frag) {
    var within = frag.pointCollide(position.x, position.y);
    if(within) {
      toggleFragmentSelection(frag);
    }
    return within;
  });
}
export function toggleFragmentSelection(frag) {
  if(isSelected(frag)) {
    removeFromSelection([frag]);
  }
  else {
    addToSelection([frag]);
  }
}
export function isSelected(frag) {
  return selectionState.fragments.indexOf(frag) !== -1;
}
export function addToSelection(frags) {
  let fragsToAdd = [];
  frags.forEach(function(frag) {
    if(!frag.fixed) {
      frag.setSelected(true);
      fragsToAdd.push(frag);
    }
  });
  selectionState.fragments = selectionState.fragments.concat(fragsToAdd); // TODO: this may cause duplicates
}
export function removeFromSelection(frags) {
  frags.forEach(function(frag) {
    frag.setSelected(false);
    selectionState.fragments = selectionState.fragments.filter(
      f => f !== frag
    );
  });
}
export function switchSelection(new_fragments) {
  let newSelection = [];
  selectionState.fragments.forEach(function(frag) {
    frag.setSelected(false);
  });
  new_fragments.forEach(function(frag) {
    if(!frag.fixed) {
      frag.setSelected(true);
      newSelection.push(frag);
    }
  });
  selectionState.fragments = newSelection;
  
}
export function startSelection(from, to) {
  if(!selectionState.moving) {
    selectionState.previouslySelectedFragments = selectionState.fragments;
    selectionState.moving = true;
    let fromGlobalCoords = positions.screenToGlobalCoords(from);
    selectionState.startX = fromGlobalCoords.x;
    selectionState.startY = fromGlobalCoords.y;
    selectionState.curX = fromGlobalCoords.x; // Note: we do not use to here since the values... 
    selectionState.curY = fromGlobalCoords.y; // ... will be overwritten by continueSelection
  }
}
/**
 * Toggle all selection candidates inside the drawn frame
 * @param {Number} to 
 */
export function continueSelection(to) {
  if(to) {
    let toGlobalCoords = positions.screenToGlobalCoords(to);
    selectionState.curX = toGlobalCoords.x;
    selectionState.curY = toGlobalCoords.y;
  }
  
  let l = Math.min(selectionState.startX, selectionState.curX);
  let r = Math.max(selectionState.startX, selectionState.curX);
  let t = Math.min(selectionState.startY, selectionState.curY);
  let b = Math.max(selectionState.startY, selectionState.curY);
  selectionState.fragments = [];
  positions.getAll().forEach(function(fragment, idx) {
    if(fragment.fixed) {
      fragment.setSelected(false);
      return false;
    }
    let fragmentGlobalTopLeft = {x: fragment.getTempX(), y:fragment.getTempY()};
    let fragmentGlobalBottomRight = {x: fragment.getTempX() + fragment.getTempW(), y: fragment.getTempY() + fragment.getTempH()};
    
    let within = (
      // top left corner
      fragmentGlobalTopLeft.x > l &&
      fragmentGlobalTopLeft.x < r &&
      fragmentGlobalTopLeft.y > t &&
      fragmentGlobalTopLeft.y < b &&
      // lower right corner
      fragmentGlobalBottomRight.x > l &&
      fragmentGlobalBottomRight.x < r &&
      fragmentGlobalBottomRight.y > t &&
      fragmentGlobalBottomRight.y < b
    );
    if(within) {
      //toggle fragment selection
      if(!selectionState.previouslySelectedFragments.includes(fragment) && !fragment.fixed)
        fragment.setSelected(true);
      else
        fragment.setSelected(false);
    }
    else {
      if(!selectionState.previouslySelectedFragments.includes(fragment) || fragment.fixed)
        fragment.setSelected(false);
      else
        fragment.setSelected(true);
    }
    if(fragment.getSelected()) {
      selectionState.fragments.push(fragment);
    }
  });
}
export function finishSelection(position) {
  selectionState.previouslySelectedFragments = selectionState.fragments;
  selectionState.moving = false;
  selectionState.startX = position.x;
  selectionState.startY = position.y;
  selectionState.curX = position.x;
  selectionState.curY = position.y;
}
export function drawFrame() {
  let renderer = main.renderer;
  if(selectionState.moving) {
    renderer.noFill();
    renderer.stroke(settings.COLORS.SELECT_FRAME);
    renderer.strokeWeight(3);

    let startScreenCoords = positions.globalToScreenCoords({x: selectionState.startX, y: selectionState.startY});
    let currentScreenCoords = positions.globalToScreenCoords({x: selectionState.curX, y: selectionState.curY});
    var sx = startScreenCoords.x;
    var sy = startScreenCoords.y;
    var cx = currentScreenCoords.x;
    var cy = currentScreenCoords.y;
    var w = cx - sx;
    var h = cy - sy;
    renderer.rect(
      sx, sy, w, h
    );
  }
}
export function setSteadyForHovered(hoveredFragment) {
  let i = 0;
  if(selectionContains(hoveredFragment)) {
    selectionState.fragments.forEach(function(steadyFrag) {
      steadyFrag.steady = true;
      i++;
    });
  }
}
