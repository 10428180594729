import * as ui from './userinterface/ui.js';

function insert(msg, ms) {
  let e = ui.addMessage(msg);
  setTimeout(() => {
    ui.removeMessage(e);
  }, ms);
}
export function message(text, ms=5000) {
  insert({text: text, type:'log', dur: ms, date: new Date().getTime()}, ms);
}
export function error(text, ms=5000) {
  insert({text: text, type:'error', dur: ms, date: new Date().getTime()}, ms);
}
