import * as util from '../utilities/utilities.js';
import * as undo from '../undo.js';

/**
 * a Change object accumulates changes that are made to a local nota and makes sure
 * they are added to the undo/redo list in {@link src/undo.js}.
 * a change is added to the undo/redo list when it's {@link Change/finalize} function
 * is called - only what is finalized can be un- and redone.
 * to prevent constant resubmission of fragments to the backend, Change subclasses
 * are strongly encouraged to implement a progress functionality for intermediate changes
 * ({@link src/util/util.js~expectFunction).
 * syncing with the database is to be implemented in subclasses of Change.
 */
export class Change {
  /**
   * constructor 
   * @param {[BaseFragment]} fragments - A list of BaseFragment instances to apply
   * the change to
   */
  constructor(fragments) {
    let self = this;
    this._finalized = false;
    this._fragments = fragments;
    // save fragment ids in case objects are not retrievable anymore
    this._fragmentIDs = [];
    this._fragments.forEach(function(fragment) {
      self._fragmentIDs.push(fragment.id);
    });
    if(this.constructor === Change) {
      console.error('Change constructor should not be called directly!');
    }
    util.expectFunction('progress', this);
    util.expectFunction('s_finalize', this);
    util.expectFunction('undo', this);
    util.expectFunction('redo', this);
  }
  addFragments(fragments) {
    this._fragments = this._fragments.concat(fragments);
  }
  /**
   * finalize makes sure that the changes are added to the undo/redo implemented
   * in {@link src/undo.js} list and calls the more specific s_finalize functions
   * of Change-subclasses.
   */
  finalize() {
    if(this.finalized) {
      return;
    }
    let self = this;
    // arguments is the array of arguments passed to this function (finalize)
    // it can vary depending on the implementations of subclasses.
    this.s_finalize.apply(this, arguments);
    let action = new undo.Action(this);
    action.sync()
      .then(function() {
        undo.addAction(action);
      })
      .catch(function(err) {
        self.undo();
        console.error(err);
        alert('There was an error moving the fragment(s). Please try again.');
        throw err;
      });
    this.finalized = true;
  }
}
