import './style.css';

import TextFragment from './fragment/text_fragment.js';
import ImageFragment from './fragment/image_fragment.js';
import AudioFragment from './fragment/audio_fragment.js';
import VideoFragment from './fragment/video_fragment.js';
import FileFragment from './fragment/file_fragment.js';

// TODO provide some types by default so they do not need to be registered
import {registerSubtype} from './fragment/fragment_production.js';
registerSubtype('text', TextFragment);
registerSubtype('image', ImageFragment);
registerSubtype('audio', AudioFragment);
registerSubtype('video', VideoFragment);
registerSubtype('file', FileFragment);

// TODO provide init method
import './main.js';
